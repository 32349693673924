import { selectDataBus, selectPOI, selectUI } from '../../store/selectors';
import { PointOfInterest } from '../../models/point.of.interest.model';
import { animationConfig } from '../../angular-animation/animation';
import { Component, OnInit, HostListener, inject, NgZone } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { GoogleAnalyticsService } from '@app/services/google-analytics.service';
import { AppConfig } from '../../../environments/environment';
import { WebRTCService } from '@app/services/web-rtc.service';
import { AuthService } from '@app/services/auth.service';
import { MapService } from '../../services/map.service';
import { Router, RouterEvent, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
    getWeatherInformation,
    toggleSideMenuInfo,
    toggleSubSideMenu,
    setRandomBgImage,
    getFlightLax,
    resetApp,
    showIdleState,
} from '../../store/actions';
import { TranslateModule } from '@ngx-translate/core';
import { StickyButtonListComponent } from '../../components/sticky-button-list/sticky-button-list.component';
import { VideoPlayerModalComponent } from '../../components/video-player-modal/video-player-modal.component';
import { SurveyComponent } from '../../components/survey/survey.component';
import { SelfieModalComponent } from '../../components/selfie-modal/selfie-modal.component';
import { AlertModalComponent } from '../../components/alert-modal/alert-modal.component';
import { StaticContentComponent } from '../../components/static-content/static-content.component';
import { VideoChatComponent } from '../../components/video-chat/video-chat.component';
import { SearchBarComponent } from '../../components/search-bar/search-bar.component';
import { SideMenuLaxComponent } from '../../components/side-menu-lax/side-menu-lax.component';
import { SideMenuInfoComponent } from '../../components/side-menu-info/side-menu-info.component';
import { SubMenuListingComponent } from '../../components/sub-menu-listing/sub-menu-listing.component';
import { IdleStateComponent } from '../../components/idle-state/idle-state.component';
import { HomeComponent } from '../home/home.component';
import { NgIf, NgStyle } from '@angular/common';
import { TimeConversionService } from '@app/services/time-conversion.service';
import { RealTimeDatabaseSyncService } from '@app/services/rtdb-sync.service';
import { ApiService } from '@app/services/api.service';
import { GIT_REV, VERSION } from '@environment/version';

@Component({
    selector: 'app-kiosk',
    templateUrl: './kiosk.component.html',
    styleUrls: ['./kiosk.component.scss'],
    animations: [
        animationConfig.flightSearchAnimationLax,
        animationConfig.flightSearchAnimation,
        animationConfig.fadeInOut,
        animationConfig.slide,
        animationConfig.fadeInOutBgImage,
        animationConfig.subMenuAnimation,
        animationConfig.subMenuInfoAnimation,
        animationConfig.flightSearch,
        animationConfig.searchModelAnimation,
    ],
    standalone: true,
    imports: [
        NgIf,
        IdleStateComponent,
        SubMenuListingComponent,
        SideMenuInfoComponent,
        NgStyle,
        SideMenuLaxComponent,
        SearchBarComponent,
        VideoChatComponent,
        RouterOutlet,
        StaticContentComponent,
        AlertModalComponent,
        SelfieModalComponent,
        SurveyComponent,
        VideoPlayerModalComponent,
        StickyButtonListComponent,
        TranslateModule,
        HomeComponent,
    ],
})
export class KioskComponent implements OnInit {
    userInactive: Subject<any> = new Subject();
    weatherRefreshInterval: number = 600000;
    animateSelfieModal: boolean = false;
    animateSubSideMenu: boolean = false;
    isMapInitialized: boolean = false;
    isStaticInfoOpen: boolean = false;
    animateSearchBar: boolean = false;
    animateSideInfo: boolean = false;
    isSearchBarOpen: boolean = false;
    isLaxTVOpened: boolean = false;
    isSelfieOpen: boolean = false;
    disableIdleState: boolean = false;
    sideMenuInfo: PointOfInterest;
    isAgent: boolean = AppConfig.isAgent;
    selectedCategory: string;
    isFlightSearch: boolean;
    dataBusSubscription;
    tokenTimeIntervalID;
    poiSubscription;
    uiSubscription;
    userActivity;
    routerSubscription: any;
    showIdleState: boolean = true;
    bgImageNumber: number;
    activeStateTransitionDuration: any;
    isBgImageChanged: boolean = false;
    defaultBgImage: string;
    randomBgImage: string;
    isVideoPlayerModalOpened: boolean = false;
    didClientInitiateCall: boolean = false;
    didSubMenuOpen: boolean = false;
    didSideMenuInfo: boolean = false;

    isEmergencyStateEnabled: boolean = false;
    playMapSteps: boolean = false;
    surveyUI: any;
    showDialog: any;

    timeService = inject(TimeConversionService);
    apiService = inject(ApiService);
    rtdbSyncService = inject(RealTimeDatabaseSyncService);
    mapInitializationProgress = {
        currentPoiIndex: 0,
        percentage: `0%`,
        totalPoiNumber: 800,
    };
    kioskInformation: any;
    iconToShow: string;
    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private webRTCService: WebRTCService,
        private authService: AuthService,
        private utility: UtilityService,
        public mapService: MapService,
        private store: Store<any>,
        private router: Router,
        private ngZone: NgZone
    ) {
        document.title = AppConfig.pageName;
        this.mapService.mapInitialized.subscribe((event) => {
            this.isMapInitialized = event.initialized;
        });

        this.rtdbSyncService.cmsRTDBSync();

        this.routerSubscription = this.router.events
            .pipe(filter((e) => e instanceof RouterEvent))
            .subscribe((e) => {
                if (e['urlAfterRedirects'] == '/login' && !AppConfig.isAgent) {
                    this.router.navigate(['/kiosk']);
                }
            });

        this.webRTCService.didClientInitiateCall.subscribe(
            (event) => (this.didClientInitiateCall = true)
        );

        this.mapService.initializationProgress.subscribe((event) => {
            this.mapInitializationProgress = event;
        });

        this.webRTCService.didStreamEnd.subscribe(
            (event) => (this.didClientInitiateCall = false)
        );

        this.utility.isPoiInfoPlayingSteps.subscribe((event) => {
            this.playMapSteps = event;

            if (!event && !this.webRTCService.showCallingState) {
                clearTimeout(this.userActivity);
                this.setIdleTimeOut();
            }
        });
    }

    ngOnInit() {
        if (AppConfig.isAgent) {
            this.authService.checkToken();

            //Check user token every 5min
            this.tokenTimeIntervalID = setInterval(() => {
                this.authService.checkToken();
            }, AppConfig.sessionTokenCheckInterval);
        }

        this.dispatchActions(); //should be first always !!
        this.dataBusSubscribe();
        this.poiSubscribe();
        this.uiSubscribe();
    }

    ngOnDestroy() {
        this.mapService.mapInitialized.unsubscribe();
        this.dataBusSubscription.unsubscribe();
        this.routerSubscription.unsubscribe();
        this.poiSubscription.unsubscribe();
        this.uiSubscription.unsubscribe();

        clearTimeout(this.activeStateTransitionDuration);
        clearInterval(this.tokenTimeIntervalID);
        clearTimeout(this.userActivity);
    }

    dispatchActions() {
        this.store.dispatch(getWeatherInformation());
        this.store.dispatch(getFlightLax());

        setInterval(() => {
            this.store.dispatch(getFlightLax());
        }, AppConfig.flifoRefreshInterval);

        setInterval(() => {
            this.store.dispatch(getWeatherInformation());
        }, this.weatherRefreshInterval);
    }

    uiSubscribe() {
        const uiState$ = this.store.select(selectUI);

        this.uiSubscription = uiState$.subscribe((uiItem) => {
            this.animateSearchBar = uiItem.windows.searchBarModal;
            this.animateSelfieModal = uiItem.windows.selfieModal;
            this.isStaticInfoOpen = uiItem.windows.staticInfoModal;
            this.bgImageNumber = uiItem.images.randomBgImage;
            this.isVideoPlayerModalOpened = uiItem.windows.videoPlayerModal;
            this.didSubMenuOpen = uiItem.windows.subSideMenu;
            this.didSideMenuInfo = uiItem.windows.sideMenuInfo;
            this.surveyUI = uiItem.survey;
            this.showDialog = uiItem.dialog.showDialog;

            if (this.isAgent) this.showIdleState = false;
            else {
                this.activeStateTransitionDuration = setTimeout(() => {
                    this.showIdleState = uiItem.windows.showIdleState;
                }, AppConfig.activeStateTransitionDuration);
            }

            this.animateSideInfo = uiItem.windows.sideMenuInfo;
            this.animateSubSideMenu = uiItem.windows.subSideMenu;
            this.isSearchBarOpen = uiItem.windows.searchBarModal;
            this.isLaxTVOpened = uiItem.windows.isLaxTVOpened;
            this.isSelfieOpen = uiItem.windows.selfieModal;
        });

        this.store.dispatch(
            setRandomBgImage({
                randomBgImage: this.utility.generateRandomNumber(
                    this.bgImageNumber,
                    4
                ),
            })
        );

        this.utility.defaultBgImage = this.bgImageNumber;
        this.utility.randomBgImage = this.utility.generateRandomNumber(
            this.utility.defaultBgImage,
            4
        );

        this.defaultBgImage = `assets/images/login/${this.utility.defaultBgImage}.jpg`;
        this.randomBgImage = `assets/images/login/${this.utility.randomBgImage}.jpg`;
    }

    poiSubscribe() {
        const poiState$ = this.store.select(selectPOI);
        this.isFlightSearch = false;
        this.poiSubscription = poiState$.subscribe((poiItem: any) => {
            this.isFlightSearch = poiItem.isFlightSearch;
            this.selectedCategory = poiItem.selectedCategory;
            this.sideMenuInfo = poiItem.poiContent;
        });
    }

    dataBusSubscribe() {
        const dataBusState$ = this.store.select(selectDataBus);
        this.dataBusSubscription = dataBusState$.subscribe((dataBusItem) => {
            this.isEmergencyStateEnabled =
                dataBusItem.emergencyBroadcast.enabled;
            this.disableIdleState = dataBusItem.disableIdleState;
            if (this.disableIdleState) {
                this.store.dispatch(showIdleState({ showIdleState: false }));
            }

            if (Object.keys(dataBusItem.kioskNames).length > 0)
                this.kioskInformation = dataBusItem.kioskNames[
                    dataBusItem.computerName
                ]
                    ? dataBusItem.kioskNames[dataBusItem.computerName]
                    : {
                          computerName: 'MSC-01-C1-IK1',
                          poiID: '6490e20a1b7d4018524f9009',
                          kioskLocation: 'L4, Terminal B',
                      };

            if (this.isEmergencyStateEnabled) {
                this.randomizeBgImage();
                this.store.dispatch(
                    toggleSideMenuInfo({
                        shouldOpen: false,
                        selectedCategory: this.selectedCategory,
                        animateSideInfo: false,
                    })
                );
                this.store.dispatch(
                    toggleSubSideMenu({
                        shouldOpen: false,
                        selectedCategory: this.selectedCategory,
                    })
                );
                this.store.dispatch(resetApp());
            }

            if (
                !this.webRTCService.didAppFinishLoading &&
                dataBusItem.turnServers.length > 0
            ) {
                this.webRTCService.initializeWebRTC(dataBusItem.turnServers); //initalizing the webRTC
                this.webRTCService.didAppFinishLoading = true;
            }
        });
    }

    randomizeBgImage() {
        if (this.isBgImageChanged) {
            this.utility.defaultBgImage = this.utility.generateRandomNumber(
                this.utility.randomBgImage,
                4
            );
            this.defaultBgImage = `assets/images/login/${this.utility.defaultBgImage}.jpg`;
            setTimeout(() => {
                this.isBgImageChanged = false;
            }, 200);
        } else {
            this.utility.randomBgImage = this.utility.generateRandomNumber(
                this.utility.defaultBgImage,
                4
            );
            this.randomBgImage = `assets/images/login/${this.utility.randomBgImage}.jpg`;
            setTimeout(() => {
                this.isBgImageChanged = true;
            }, 200);
        }
    }

    logDoubleTap() {
        console.log(this.kioskInformation);

        this.apiService
            .onLogDoubleTap({
                kioskName: this.kioskInformation.computerName,
                version: `${VERSION} (${GIT_REV})`,
            })
            .subscribe({
                next: (res) => {
                    console.log(res);
                    this.iconToShow = 'assets/icon/check.svg';

                    setTimeout(() => {
                        this.iconToShow = '';
                    }, 1000);
                },
                error: (error) => {
                    this.iconToShow = 'assets/icon/error.svg';

                    setTimeout(() => {
                        this.iconToShow = '';
                    }, 1000);
                },
            });
    }

    setIdleTimeOut() {
        if (this.disableIdleState) {
            return;
        }
        this.userActivity = setTimeout(() => {
            this.userInactive.next(undefined);
            this.randomizeBgImage();

            this.store.dispatch(
                toggleSideMenuInfo({
                    shouldOpen: false,
                    selectedCategory: this.selectedCategory,
                    animateSideInfo: false,
                })
            );

            setTimeout(() => {
                this.store.dispatch(
                    toggleSubSideMenu({
                        shouldOpen: false,
                        selectedCategory: this.selectedCategory,
                    })
                );

                setTimeout(() => {
                    this.googleAnalyticService.onStateChange(
                        'idle',
                        this.timeService.getUnix() -
                            this.googleAnalyticService.sessionStartTime
                    );
                    this.store.dispatch(resetApp());
                }, 500);
            }, 350);
        }, AppConfig.appResetTimer);
    }

    @HostListener('mousewheel')
    @HostListener('touchstart')
    @HostListener('mouseenter')
    @HostListener('mouseleave')
    @HostListener('mousedown')
    @HostListener('mouseover')
    @HostListener('mousemove')
    @HostListener('mouseout')
    @HostListener('mouseup')
    @HostListener('click')
    @HostListener('touch')
    resetApp() {
        clearTimeout(this.userActivity);
        if (
            this.webRTCService.showCallingState ||
            this.isVideoPlayerModalOpened ||
            this.didClientInitiateCall ||
            this.playMapSteps ||
            this.showIdleState ||
            this.isAgent
        )
            return;
        this.setIdleTimeOut();
    }
}
